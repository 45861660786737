import '../black.css';

export default function Black() {
  return (
    <main className="container">
      <section>
        <div className="logo">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/final-logo.jpg" alt="Geoponika Full Logo" />
        </div>
      </section>

      {/* IMAGES */}

      <section>
        <div className="hero landscape">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/japan2.jpg" alt="Japan" />
        </div>
      </section>

      <section>
        <div className="hero landscape">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/final1.jpg" alt="2B" />
        </div>
      </section>

      <section>
        <div className="hero landscape">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/final2.jpg" alt="3" />
        </div>
      </section>

      <section>
        <div className="hero landscape">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/final4.jpg" alt="El Centro" />
        </div>
      </section>

      <section>
        <div className="hero landscape">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/mayer2.jpg" alt="Mayer" />
        </div>
      </section>


      <section className="menu-container">
        <div className="menu">
          <img src="https://05ec48578e12534030.temporary.link/geoponika/black-text.png" alt="Geoponika Text Logo" />
          <h2>Agricultural Pursuits</h2>
          <h2>Los Angeles</h2>
          <h3>Landscapes, Gardens & Glasshouses</h3>
          <h4>For inquiries or selected portfolio<br /><a href="mailto:info@geoponika.co" target="_blank">info@geoponika.co</a></h4>
          <h4><a href="https://instagram.com/geoponika_" target="_blank">@geoponika_</a></h4>
        </div>
      </section>
    </main >
  )
}